import * as React from 'react'
import styled from '@emotion/styled'
import Plx from 'react-plx';

import { fonts } from '../../styles/variables'

import Headroom from 'react-headroom'
import { FormattedMessage } from 'react-intl';
import Scrollspy from 'react-scrollspy'

import Select, {components} from 'react-select'
import { ElementConfig } from 'react'
import chroma from 'chroma-js';

let Scroll = require('react-scroll');

let scroller = Scroll.scroller;

import { BsChevronCompactDown } from 'react-icons/bs'


const scrollerData = [
  {
    start: 0,
    end: '#chapter1',
    properties: [
      {
        startValue: 0,
        endValue: 65,
        property: 'translateY',
        unit:'%'

      }


    ],
  },
];

const LilaArrowWrap = styled.div`
width:54px;
display:flex;
justify-content:center;
align-items:center;
`




const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>

) => {
  return (
    <components.DropdownIndicator {...props}>
      <LilaArrowWrap><BsChevronCompactDown /></LilaArrowWrap>

    </components.DropdownIndicator>
  );
};
const colourOptions = [
  { value: 0,color:'darkgrey', label: <FormattedMessage id={'imprint_header_1'}/>},
  { value: 1,color:'darkgrey', label:<FormattedMessage id={'imprint_header_2'}/> },
    { value: 2,color:'darkgrey', label: <FormattedMessage id={'imprint_header_3'}/>},

]

const colourStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.isFocused ? 'rotate(180deg)' : null
  }),
  indicatorSeparator: (styles, state) => {
    return {
      ...styles,
      marginBottom: '18px',
      marginTop:'18px',
      opacity:!state.isFocused ? 0: 0,
      color:'var(--border)'

    };
  },

  control: (styles, state) => ({
    ...styles,
    '&::after': { borderColor: 'var(--hase)' },
    '&:hover': { borderColor: 'none', backgroundColor:'rgba(0, 0, 0, 0.06)' },
    borderColor:!state.isFocused ?'var(--border)':'var(--hase)',
    boxShadow: 'none',
    borderWidth:!state.isFocused ?'1px':'2px',
    borderTop:!state.isFocused ?'1px':'0px',
    borderLeft:!state.isFocused ?'1px':'0px',
    borderRight:!state.isFocused ?'1px':'0px',
    background:'rgba(0, 0, 0, 0.03)',
    color:'var(--txt) !important',
    borderRadius:'1px',
    height:'50px'}),
  singleValue: styles => ({ ...styles, backgroundColor: 'none',

    color:'var(--txt) !important' }),
  placeholder: styles => ({ ...styles, fontFamily: fonts.bcHairLine,

    color:'var(--txt) !important' }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      '&:hover': {  color: 'black'},
      '&:focus': { color: 'black'},

      backgroundColor: isDisabled
        ? 'var(--bg)'
        : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : 'var(--selectBg)',
      color: isDisabled
        ? 'var(--txt)'
        : isSelected
          ? isFocused ? 'var(--bg)' : 'var(--bg)'
          : 'var(--txt)',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };





  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
};


const StyledBook = styled.header`
background-color: var(--bg);
width:100%;
height: 100vh;
overflow: hidden;
position:relative;


`


const PurpleBlock = styled.div`
height: 44%;
width: calc(100% - 24rem);
left: 6rem;
background-color: #6f6277;
position: absolute;
bottom: 0;

 @media screen and (max-width: 1199px) {
display:none;}


`

const GreenBlock = styled.div`
height: 55%;
width: calc(100% - 24rem);
left: 12rem;
background-color: #578280;
position: absolute;
bottom: 0;

 @media screen and (max-width: 1199px) {
display:none;}

`


const MainBlock = styled.div`
height: 66%;
width: calc(100% - 24rem);
padding: 9rem 0 0 18rem;
left: 18rem;
background-color: var(--main);
position: absolute;
bottom: 0;
 @media screen and (max-width: 1199px) {
width:100%;
padding: 9rem 0 0 10%;

left: 0rem;

}

`

const LilaMain = styled.div`
position: absolute;
left: 9rem;
bottom: 67%;

 @media screen and (max-width: 1199px) {
display:none

}
`

const Lh = styled.div`
width:256px;
margin-right:1.6rem;
align-items:center;
.cls-1{fill:var(--hase);}
.cls-2{fill:none;}
.cls-3{fill:var(--hase);}
`

const LilaSymbol = styled.div`
width:54px;
justify-content:center;
align-items:center;
  .cls-1{
  fill:var(--hase) !important;
  }



.rotate {
  animation: rotation 8s infinite linear;

}

@keyframes rotation {
  from {
    transform: rotate(0deg) scale(1);
  }
  to {
    transform: rotate(359deg) scale(1);
  }
}



  @keyframes rotate{

    100%{
      transform:rotate(360deg);
    }
  }


`

const LilaHase = styled.div`
width:100%;
margin-bottom:2.4rem;
display:flex;
align-items:center;
height:30px;
`


const H1 = styled.h1`
font-family:${fonts.bcExtraLight};
font-weight:normal;
`
const ScrollToRead = styled.div`
position:absolute;
font-family:${fonts.bcHairLine};
font-size:0.8rem;

z-index:1;
height:5rem;
width:140px;
padding-right:1rem;
border-right: 1px solid var(--border);
display:flex;
justify-content:flex-start;
bottom:0;
left:25%;

 @media screen and (max-width: 1199px) {
left:10%;
}

 @media screen and (max-width: 1400px) {
left:30%;
}

`
const LilaScroller = styled.div`
position:absolute;
height:1rem;
border-right: 1px solid var(--hase);
width:140px;




`

const H2 = styled.h2`
font-family:${fonts.bcHairLine};
font-weight:normal;
color:var(--txt);
margin-top:0px;
font-size:1.8rem;
`


const HeadroomWrapper = styled.div`
display:none;
 @media screen and (max-width: 1199px) {
display:block;
}

position:absolute;
width:100%;


.headroom-wrapper{
}
.headroom {
  top: 0px;
  left: 0;
  right: 0;
  zIndex: 99999999 !important;
    margin-top:82px;
    position:relative;

       @media screen and (max-width:960px){
margin-top:52px;  }


}

.headroom headroom--unpinned headroom--scrolled{
z-index:99999 !important;

}

.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
    z-index:99999 !important;

}
.headroom--unpinned {
  position: fixed;
  transform: translateY(-100%);
  z-index:99999 !important;

}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
    z-index:99999 !important;


}
`

const LilaBackground = styled.div`
background:var(--headerBg);
  width:100%;
  padding-left:10%;
padding-right:10%;








`


const LilaNav = styled.div`
width:100%;

  margin-bottom:2rem;
z-index:9999999;
background:var(--bg);
transition:all .4s ease;


   @media screen and (max-width:960px){
margin-bottom:2px;  }

font-family:${fonts.bcHairLine};


a{
color:var(--txt) !important;
}




`


const StyledSelect = styled(Select)`
width:100%;
background:var(--filter);
height:50px;
letter-spacing:0px;
position:absolute;
z-index:999999999;

      font-size:calc(18px + (18 - 18) * ((100vw - 300px) / (1600 - 300)));

.css-26l3qy-menu{
border-radius:1px;
}



.css-cya496-control{

min-height:56px;
}
`


const Nav = styled.div`
width:100%;
display:flex;
justify-content:center;
height:50px;
align-items:center;
font-size:1rem;
position:relative;
z-index:999999;
//background:var(--headerBg);



`




const LilaScrollspy = styled(Scrollspy)`
display:none;
`
class ImprintBook extends React.Component {
  constructor(props: any) {
    super(props)

    this.state = {
selectedNavTab:null
    }
    this.handleScrollspy=this.handleScrollspy.bind(this)
  }


  handleLilaSelection = (selection) => {
if(selection.value===0){
  scroller.scrollTo('chOne', {
    duration: 1500,
    smooth: true,
  })
}

if(selection.value===1) {
  scroller.scrollTo('chTwo', {
    duration: 1500,
    smooth: true,
  })
}

if(selection.value===2) {
  scroller.scrollTo('chThree', {
    duration: 1500,
    smooth: true,
  })
}
}


handleScrollspy(el) {
    //console.log(el)
    if(el) {
      if (el.id === 'section-1') {
        this.setState({
          selectedNavTab: colourOptions[0]
        })
      }

      if (el.id === 'section-2') {
        this.setState({
          selectedNavTab: colourOptions[1]
        })
      }

      if (el.id === 'section-3') {
        this.setState({
          selectedNavTab: colourOptions[2]
        })
      }
    }
}

  componentDidMount() {

  }

  render() {
    return(


        <StyledBook id={'component'}>
          <LilaScrollspy onUpdate={ (el)=>this.handleScrollspy(el) }  items={ ['section-1', 'section-2', 'section-3'] } currentClassName="is-current">
          </LilaScrollspy>
          <HeadroomWrapper>
            <Headroom pinStart={0}>
              <LilaNav id={'component'}>
                <LilaBackground>
                  <Nav>
                    <StyledSelect
                      name="styles[]"
                      required={true}
                      closeMenuOnSelect={true}
                      placeholder={<FormattedMessage id="pm_0" />}
                      options={colourOptions}
                      styles={colourStyles}
                      selectedOption
                      onChange={this.handleLilaSelection}
                      components={{ DropdownIndicator }}
                      value={this.state.selectedNavTab}
                    ></StyledSelect>                  </Nav>


                </LilaBackground>

              </LilaNav>
            </Headroom></HeadroomWrapper>

<ScrollToRead ><Plx style={{display:'flex'}} parallaxData={scrollerData}><FormattedMessage id={'scroll'}/>
  <LilaScroller ></LilaScroller></Plx>
</ScrollToRead>

          <LilaMain></LilaMain>


          <PurpleBlock></PurpleBlock>
          <GreenBlock></GreenBlock>
          <MainBlock>
            <LilaHase>
              <Lh>
                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 372.33 45.05">
                  <defs>
                  </defs>
                  <path className="cls-1" d="M14.76,21.35h7.85V56.9h24.1v7H14.76Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M58.07,21.35h7.85v42.5H58.07Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M80.53,21.35h7.85V56.9h24.1v7H80.53Z" transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M239.11,63.85h-7.85V46.1H207.51V63.85h-7.85V21.35h7.85V39.1h23.75V21.35h7.85Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1"
                        d="M331.8,29a18,18,0,0,0-7.42-1.55c-3,0-5.3.54-6.78,1.6a4.78,4.78,0,0,0-2.22,4,3.25,3.25,0,0,0,1.32,2.75,9,9,0,0,0,3.35,1.5q2,.51,5.58,1.05a64,64,0,0,1,8.85,1.93A13.43,13.43,0,0,1,340.43,44c1.66,1.78,2.5,4.29,2.5,7.52q0,6.51-5,9.85t-13.57,3.35A30.45,30.45,0,0,1,313.5,63a25.28,25.28,0,0,1-7.57-4.25l3.45-6.3a22,22,0,0,0,6.77,3.8,24.17,24.17,0,0,0,8.38,1.45q5.19,0,7.7-1.65a4.94,4.94,0,0,0,2.5-4.25,3.55,3.55,0,0,0-1.38-3,9.44,9.44,0,0,0-3.4-1.63q-2-.54-5.82-1.2a63.73,63.73,0,0,1-8.58-1.9,13.1,13.1,0,0,1-5.77-3.62,10.13,10.13,0,0,1-2.4-7.18,11.27,11.27,0,0,1,2.12-6.85,13.2,13.2,0,0,1,6-4.42,24.23,24.23,0,0,1,9-1.53,23.67,23.67,0,0,1,9.77,1.93,26.54,26.54,0,0,1,7.13,4.47l-3.35,6A26,26,0,0,0,331.8,29Z"
                        transform="translate(-14.76 -19.7)"/>
                  <path className="cls-1" d="M386.09,39.2V46H363V56.9h24.1v7H355.14V21.35h31.95v7H363V39.2Z"
                        transform="translate(-14.76 -19.7)"/>
                  <polygon className="cls-2" points="129.98 14.85 122.23 28.85 137.78 28.85 129.98 14.85"/>
                  <polygon className="cls-3"
                           points="130.03 0 105.03 44.15 113.68 44.15 118.68 35.1 122.23 28.85 129.98 14.85 137.78 28.85 141.33 35.1 146.38 44.15 155.03 44.15 130.03 0"/>
                  <polygon className="cls-2" points="259.86 14.85 252.11 28.85 267.66 28.85 259.86 14.85"/>
                  <polygon className="cls-3"
                           points="259.91 0 234.91 44.15 243.56 44.15 248.56 35.1 252.11 28.85 259.86 14.85 267.66 28.85 271.21 35.1 276.26 44.15 284.91 44.15 259.91 0"/>
                </svg>
              </Lh>
              <LilaSymbol>
                <svg className="rotate" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 241.36 179.42">
                  <defs>
                  </defs>
                  <path className="cls-1"
                        d="M612.62,378.23,762.71,495,536.84,509.45l75.78-131.22m-1-5.15-81,140.22L772,497.82,611.62,373.08Z"
                        transform="translate(-530.64 -373.08)"/>
                  <path className="cls-1"
                        d="M612.62,377l41.91,11.84,55.64,15.73,57.17,92.32-75.55,52L554.7,516.38,535,510.93l25.64-82.21,52-51.77m-1-3.87-54,53.8-27,86.42,23.21,6.42L692.49,552.5,772,497.82l-59.6-96.25-56.93-16.09-43.85-12.4Z"
                        transform="translate(-530.64 -373.08)"/>
                </svg>
              </LilaSymbol>
            </LilaHase>
            <H1>{this.props.title}</H1>
            <H2>{this.props.subTitle1}</H2>
            <H2>{this.props.subTitle2}</H2>

          </MainBlock>
    </StyledBook>



    )
  }
}


export default ImprintBook


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
