import * as React from 'react'
import { FormattedMessage } from 'react-intl';

import IndexLayout from '../layouts'
import LilaSection from '../components/LilaSection'
import styled from '@emotion/styled'
import ImprintNav from '../components/nav/ImprintNav'
import ImprintBook from '../components/imprint/ImprintBook'
var Scroll = require('react-scroll');
var Element = Scroll.Element;



const Chapter = styled.div`
width:100%;
display:flex;
padding-left: 8rem;
padding-right: 12rem;
padding-top: 4rem;
text-align:left;

 @media screen and (max-width: 1199px) {
padding-left: 10%;
padding-right: 10%;

}
`

const ChapterText = styled.div`
width:70%;
margin-bottom:4rem;

 @media screen and (max-width: 1199px) {

width:100%;

}


`

const SubP = styled.p`
text-align: left;
margin-top:1.2rem;
margin-bottom:1rem;
letter-spacing:1px;

`

const P = styled.p`
text-align: left;
letter-spacing:1px;
font-size:1rem !important;
margin-bottom:0px;
padding-bottom:0px;
display:flex;

`



const H1 = styled.h1`
font-size:2rem;
`

const Widerruf = styled.div`
border: 1px solid var(--border);
padding:20px;
`

const LilaMail = styled.a`
margin-left:0.3rem;
color:var(--hase);
`


const Impressum = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <ImprintBook title={<FormattedMessage id={'imprint_0'}/>} subTitle1={''} subTitle2={''}/>


    <Element id="chOne"/>
    <div id={'section-1'}>
      <LilaSection id={'first'} title={''}></LilaSection>
      <Chapter id={'chapter1'}>
        <ImprintNav />
        <ChapterText>
          <H1><FormattedMessage id={'imprint_header_0'}/>: </H1>
          <SubP> <FormattedMessage id={'imprint_1'}/> </SubP>

          <P> <FormattedMessage id={'imprint_2'}/></P>
          <P> <FormattedMessage id={'imprint_3'}/></P>
          <P> <FormattedMessage id={'imprint_4'}/></P>

          <P> <FormattedMessage id={'imprint_5'}/></P>
          <br/>
          <P> <FormattedMessage id={'imprint_6'}/> <LilaMail href={'mailto:info@lilahase.com'}>info@lilahase.com</LilaMail></P>
          <P> <FormattedMessage id={'imprint_6_1'}/> <LilaMail href={'https://lilahase.com'}>lilahase.com</LilaMail></P>



        </ChapterText>
      </Chapter>
    </div>

    <Element id="chTwo"/>
    <div id={'section-2'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter2'}>
        <ImprintNav />
        <ChapterText>
          <H1><FormattedMessage id={'imprint_header_2'}/> </H1>
          <SubP></SubP>

          <P><FormattedMessage id={'imprint_bank_1'}/></P>
          <P><FormattedMessage id={'imprint_bank_2'}/></P>
          <P><FormattedMessage id={'imprint_bank_3'}/></P>
          <P><FormattedMessage id={'imprint_bank_4'}/></P>

        </ChapterText>
      </Chapter>

    </div>


    <Element id="chThree"/>
    <div id={'section-3'}>
      <LilaSection title={''} id={''}></LilaSection>
      <Chapter id={'chapter3'}>
        <ImprintNav />
        <ChapterText>
          <H1> <FormattedMessage id={'imprint_header_3'}/></H1>
          <SubP></SubP>
          <P><FormattedMessage id={'imprint_hrb_1'}/></P>
          <P><FormattedMessage id={'imprint_hrb_2'}/></P>
          <P><FormattedMessage id={'imprint_hrb_3'}/></P>
          <P><FormattedMessage id={'imprint_hrb_4'}/></P>



        </ChapterText>
      </Chapter>
    </div>


  </IndexLayout>
)

export default Impressum
